import React from "react";
import Logo from "../Logo/Logo";
import Lettdugnad from "../Logo/Lettdugnad";
import styles from "./Branding.module.scss";
import { Caption } from "../Typography/Typography";

const Branding = ({ isHoopit, color }) => {
  return (
    <div className={styles.logo}>
      {isHoopit ? (
        <Logo color={color} />
      ) : (
        <>
          <Lettdugnad color={color} />
          <Caption>
            Drevet av <Logo color="var(--color-gray-500)" />
          </Caption>
        </>
      )}
    </div>
  );
};

export default Branding;
