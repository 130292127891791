import React from "react";
import Logo from "../Logo/Logo";
import styles from "./Footer.module.scss";
import Branding from "../Branding/Branding";

const Footer = ({ isHoopit }) => {
  return (
    <footer className={styles.footer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2312.98 217.27"
        preserveAspectRatio="none"
      >
        <path
          d="M0 217.27h2312.98v-115.2c-60.04-11.78-131.14-14.61-236.83 12.07-80.2 20.25-244.14 65.5-244.14 65.5-99.91 19.92-155.14 35.05-295.13 26.15a1428.928 1428.928 0 01-228.46-38.87c-115.44-28.42-193.62-57.87-245.29-72.45-165.47-46.7-168.22-53.48-286.79-71.7-223.24-34.3-426.78-16.67-542.71 0C92.61 49.66 40.94 62.02 0 76.14v141.13z"
          fill="var(--color-gray-700"
        />
      </svg>
      <div>
        <Branding isHoopit={isHoopit} color="#ffffff" />
        <ul>
          <li>Hoopit</li>
          <li>Org.nr. 917 100 179</li>
          <li>+47 928 41 091</li>
          <li>post@hoopit.io</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
