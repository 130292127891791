import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Logo from "../components/Logo/Logo";
import {
  Body1,
  Headline1,
  Headline6,
  Caption,
  Body2,
} from "../components/Typography/Typography";
import Button from "../components/Button/Button";
import nif from "../images/nif.png";
import styles from "./pageStyles/index.module.scss";
import Blob from "../components/Blob/Blob";
import Footer from "../components/Footer/Footer";
import Testimonial from "../components/Testimonial/Testimonial";
import useSiteMetadata from "../hooks/useSiteMetadata";
import Branding from "../components/Branding/Branding";
import Img from "gatsby-image";

const IndexPage = () => {
  const { siteUrl } = useSiteMetadata();
  const isHoopit = siteUrl.toLowerCase().includes("hoopit");
  const { football, football_mobile } = useStaticQuery(graphql`
    query {
      football: file(relativePath: { eq: "fotball.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      football_mobile: file(relativePath: { eq: "fotball-mobil.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout className={styles.wrapper}>
        <SEO title="Home" />
        <Blob position="right center" />
        <header className={styles.header}>
          <div className={styles.branding}>
            <Branding isHoopit={isHoopit} />
          </div>
          <div className={styles.twoColumn}>
            <div className={styles.image}>
              <Img fluid={football.childImageSharp.fluid} alt="Ishockey" />
              <Img
                fluid={football_mobile.childImageSharp.fluid}
                alt="Ishockey"
              />
            </div>
            <div>
              <Headline1>Digital dugnad på to minutter</Headline1>
              <Body1>
                Spørreundersøkelser fra sponsorer og næringsliv blir penger i
                klubbkassa, raskt og enkelt.
              </Body1>
              <Button url="/registrer" primary>
                Kom i gang!
              </Button>
            </div>
          </div>
          <a href="/#hvordan-fungerer-det">
            <Caption className={styles.readMore}>
              <span>Les mer</span>
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.005 5.209v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0a.996.996 0 000 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0 10-1.41-1.41l-4.88 4.88V5.209c0-.55-.45-1-1-1s-1 .45-1 1z"
                  fill="var(--color-gray-700)"
                />
              </svg>
            </Caption>
          </a>
          <img
            className={styles.nifLogo}
            src={nif}
            alt="Norges idrettsforbund – Hoopit"
          />
        </header>
        <div className={styles.body}>
          <Blob position="left center" />
          <div className={styles.innerBody}>
            <div id="hvordan-fungerer-det" />
            <Headline1>Hvordan fungerer det?</Headline1>
            <Body1>
              Vi stiller med næringslivspartnere som ønsker å sende korte
              markedsundersøkelser til medlemmene i klubben. Medlemmene bidrar
              med svarene de gir fra seg, noe som tar om lag to minutter.
              Idrettslaget får betalt per svar og får i tillegg en provisjon på
              eventuelle kjøp. Det betyr at en klubb med 500 medlemmer kan tjene
              ca. 15.000 kr per dugnad.
              <br />
              <br />
              Medlemmenes personopplysninger blir trygt ivaretatt, og de kan
              selv velge om de ønsker å bli kontaktet av bedriften i etterkant
              av undersøkelsen.
            </Body1>
            <br />
            <Button url={"/registrer"} primary>
              Kom i gang
            </Button>
            <Headline1>Hva med idrettslagets sponsorer?</Headline1>
            <Body1>
              Dere kan invitere klubbens egne sponsorer til å bli med. Det
              skaper merverdi for sponsorene og kan føre til et tettere og bedre
              samarbeid mellom klubb og sponsor.
              <br />
              <br />
              Dere velger selv når dugnaden skal gjennomføres, og hvilke av våre
              næringslivspartnere dere ønsker å inkludere. Dermed blir det ingen
              interessekonflikter med de faste sponsorene til klubben.
            </Body1>
            <Headline1>Hva koster det?</Headline1>
            <Body1>
              Dugnaden er gratis for klubben, og de fleste idrettslagene bruker
              bare noen få timer i måneden på å administrere sine digitale
              dugnader. Vi bidrar underveis med påminnelser og forslag til
              hvordan du kan dra dugnaden i mål i en hektisk hverdag.
            </Body1>
            <div className={styles.contactButton}>
              <Button url="/registrer" primary>
                Kom i gang!
              </Button>
            </div>
          </div>
        </div>
      </Layout>
      <Footer isHoopit={isHoopit} />
    </>
  );
};

export default IndexPage;
