import React from "react";
import styles from "./Blob.module.scss";

const Blob = ({ position }) => {
  const SVG = ({ children, width, height, style }) => (
    <svg
      className={styles.blob}
      width={width}
      height={height}
      style={{ ...style, objectPosition: position, zIndex: -1 }}
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
  if (position.includes("top") && position.includes("right")) {
    return (
      <SVG width="252" height="419" style={{ top: 0, right: 0 }}>
        <path
          d="M252 0H102.872C84.857 49.703 64.328 93.704 40.837 128.703-80.207 309.047 97.16 393.722 252 418.446V0z"
          fill="#FEF7F0"
        />
      </SVG>
    );
  } else if (position.includes("bottom") && position.includes("left")) {
    return (
      <SVG width="356" height="596" style={{ bottom: 0, left: 0 }}>
        <path
          d="M330.27 596H0V.466C97.294 49.802 192.344 121.717 312 337c59.499 107.05 50.973 192.455 18.27 259z"
          fill="#FEF7F0"
        />
      </SVG>
    );
  } else if (position.includes("right") && position.includes("center")) {
    return (
      <SVG width="782" height="907" style={{ top: 0, right: 0 }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M442.362 0H782v906.206C514.621 846.644-238.129 571.409 76.5 308.5c33.896-28.324 73.047-54.977 113.825-82.738C279.114 165.317 375.614 99.622 442.362 0z"
          fill="var(--color-orange-200)"
        />
      </SVG>
    );
  } else if (position.includes("left") && position.includes("center")) {
    return (
      <SVG width="274" height="680" style={{ left: 0, bottom: "5%" }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 679.865c82.199-40.159 192.95-99.331 233.27-144.331 67.23-75.034 41.229-151.951-18.27-259C135.011 132.619 66.018 52.773 0 .911v678.954z"
          fill="#EFF7FF"
        />
      </SVG>
    );
  }
};

export default Blob;
